.ant-message{
    margin-top: 150px;
}
.ant-table table{
    text-align: center;
    font-size: 12px;
}
.ant-table table th{
    text-align: center;
}
.ant-input{
    height: 34px;
    width:100%;
}
.gutter-box{
    margin-top: 20px;
    margin-left: 20px;
}
