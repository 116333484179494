/* @import url(../../assets/plugins/bootstrap/css/bootstrap.min.css);
@import url(../../assets/css/style.css);
@import url(../../assets/css/headers/header-default.css);
@import url(../../assets/css/footers/footer-v1.css);
@import url(../../assets/plugins/animate.css);
@import url(../../assets/plugins/line-icons/line-icons.css);
@import url(../../assets/plugins/font-awesome/css/font-awesome.min.css);
@import url(../../assets/plugins/sky-forms-pro/skyforms/css/sky-forms.css);
@import url(../../assets/plugins/sky-forms-pro/skyforms/custom/custom-sky-forms.css);
@import url(../../assets/css/bootstrap-select.min.css);
@import url(../../assets/css/select2.css);
@import url(../../assets/css/select2-metronic.css);
@import url(../../assets/css/multi-select.css);
@import url(../../assets/css/bootstrap-datetimepicker.css);
@import url(../../assets/css/theme-skins/dark.css);
@import url(../../assets/css/custom.css); */

a {
  color: #ff5b22;
}
a:hover,
a:focus {
  color: #fb5015;
  text-decoration: none;
}
.fr {
  float: right;
}
body,
label,
input,
span,
table,
td,
th {
  font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
body {
  letter-spacing: 1px;
  font-size: 14px;
}
.page-header.navbar {
  width: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  height: 100px;
  min-height: 100px;
  filter: none;
  background-color: #2f3138;
}
.page-header.navbar .page-logo {
  float: left;
  padding-left: 0;
  padding-right: 0;
}
.page-header.navbar .page-logo .logo-name {
  display: block;
  width: 260px;
  height: 40px;
  float: left;
}
.page-header.navbar .page-logo .logo-name .logo-default {
  margin: 30px 0 0;
}
.page-header.navbar .dropdown-film {
  float: left;
  padding-left: 40px;
  padding-top: 5px;
}
.page-header.navbar .dropdown-film a {
  color: #ccc;
  font-size: 16px;
}
.page-header.navbar .dropdown-film .dropdown-menu {
  min-width: 90px;
  padding-top: 6px;
  padding-bottom: 16px;
  left: 35px;
}
.page-header.navbar .dropdown-film .dropdown-menu a {
  color: #666;
  font-size: 14px;
  padding: 8px 15px 3px 15px;
}
.page-header.navbar .dropdown-film .dropdown-menu a:hover,
.page-header.navbar .dropdown-film .dropdown-menu a:focus,
.page-header.navbar .dropdown-film .dropdown-menu a:active {
  background: transparent;
  color: #ff5b22;
}
.search-input {
  float: right;
  width: 360px;
  display: table;
  /* margin-top: 30px; */
  margin-right: 180px;
}
.search-input .form-control {
  -webkit-border-radius: 20px 0 0 20px;
  -moz-border-radius: 20px 0 0 20px;
  border-radius: 20px 0 0 20px;
  height: 40px;
  line-height: 40px;
  background-color: #44464c;
  border: 0;
  padding-left: 23px;
}
.search-input .form-control:focus,
.search-input .form-control:active,
.search-input .form-control:hover {
  border: 0;
}
.search-input .input-group-addon {
  -webkit-border-radius: 0 20px 20px 0;
  -moz-border-radius: 0 20px 20px 0;
  border-radius: 0 20px 20px 0;
  background-color: #ff5b22;
  color: #fff;
  border-color: #ff5b22;
  font-size: 16px;
  width: 70px;
}
.navbar-nav li a {
  padding-top: 30px;
}
.navbar-nav li a img {
  display: inline-block;
  margin-right: 9px;
}
.nav .dropdown-toggle .username {
  color: #ccc;
}
.nav .dropdown-toggle .fa-angle-down {
  color: #ccc;
  display: inline-block;
  margin-left: 3px;
}
.nav .dropdown-toggle:hover,
.nav .dropdown-toggle:active,
.nav .dropdown-toggle:focus {
  background: transparent;
}
.nav .open a {
  background: transparent;
}
.nav .open a:hover,
.nav .open a:active,
.nav .open a:focus {
  background: transparent;
}
.nav .dropdown-menu {
  padding-bottom: 12px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
}
.nav .dropdown-menu a {
  padding-top: 8px;
}
.nav .dropdown-menu:before {
  position: absolute;
  top: -6px;
  right: 10px;
  left: auto;
  display: inline-block !important;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}
.nav .dropdown .personal-icon {
  display: inline-block;
  background: url("../img/personal-icon.png") no-repeat 11px 12px;
  padding-left: 34px;
}
.nav .dropdown .personal-icon:hover,
.nav .dropdown .personal-icon:active,
.nav .dropdown .personal-icon:focus {
  background: #ffffff url("../img/personal-icon-active.png") no-repeat 11px 12px;
  padding-left: 34px;
  color: #ff5b22;
}
.nav .dropdown .exitsystem-icon {
  display: inline-block;
  background: url("../img/exitsystem-icon.png") no-repeat 11px 12px;
  padding-left: 34px;
}
.nav .dropdown .exitsystem-icon:hover,
.nav .dropdown .exitsystem-icon:active,
.nav .dropdown .exitsystem-icon:focus {
  background: #ffffff url("../img/exitsystem-icon-active.png") no-repeat 11px 12px;
  padding-left: 34px;
  color: #ff5b22;
}
@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding: 0;
  }
}
.page-header.navbar.navbar-fixed-top {
  /* z-index: 1; */
}
.sky-form .checkbox input + i::after {
  color: #ff5b22;
}
.sky-form .radio input + i::after {
  background: #ff5b22;
  width: 9px;
  height: 9px;
  left: 3px;
  top: 3px;
}
.header {
  width: auto;
  border-bottom: 0;
}
.header .logo {
  max-width: 100%;
  max-height: 100%;
}
.header .logo img {
  margin: 15px 0 15px 0;
}
.header .logo-name {
  margin-top: 39px;
  text-align: center;
}
.footer-v1 .footer h2 {
  font-size: 14px;
  border-bottom: 0;
}
.page-number {
  text-align: center;
  font-size: 15px;
  margin-bottom: 30px;
}
.page-number .number-strip {
  float: left;
}
.page-number .number-strip .strip-show {
  color: #666;
  display: inline-block;
  border: 1px solid #e5e5e5;
  padding: 6px 9px 6px 11px;
  margin-bottom: 0;
}
.page-number .number-strip .strip-size {
  display: inline-block;
  margin: 0 4px 0 3px;
  color: #666;
}
.page-number .number-strip .strip-total {
  display: inline-block;
  margin-left: 4px;
  color: #888;
  margin-bottom: 0;
}
.page-number .number-info button {
  color: #555;
  margin-right: 4px;
  letter-spacing: 1.3px;
}
.page-number .number-info .number-info-more {
  border-color: #fff;
  padding: 2px 0;
  margin-right: 2px;
  margin-left: 5px;
  vertical-align: text-bottom;
}
.page-number .number-info .number-info-more:hover,
.page-number .number-info .number-info-more:focus,
.page-number .number-info .number-info-more:active {
  border-color: #fff;
  background: #fff;
}
.page-number .number-info .btn.active {
  background: #eee;
  color: #23527c;
}
.footer-v1 {
  font-weight: 200;
}
.footer-v1 .footer {
  background: #191c24;
  padding-top: 26px;
  padding-bottom: 34px;
}
.footer-v1 .headline {
  border-bottom: 0;
  position: relative;
  margin-bottom: 31px;
}
.footer-v1 .headline h2 {
  line-height: 16px;
  padding-bottom: 0;
  filter: alpha(opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.footer-v1 .headline h2:hover,
.footer-v1 .headline h2:focus {
  color: #F76515;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  cursor: pointer;
}
.footer-v1 .headline .posts-line {
  position: absolute;
  top: 5px;
  left: 80%;
  display: inline-block;
  width: 1px;
  height: 17px;
  background: #2c2e2d ;
}
.footer-v1 .link-list a {
  filter: alpha(opacity=30);
  -moz-opacity: 0.3;
  opacity: 0.3;
  font-size: 14px;
  padding: 7px 0 2px 0;
  color: #fff;
}
.footer-v1 .link-list a:hover,
.footer-v1 .link-list a:focus {
  color: #F76515;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.footer-v1 .link-list li {
  border-top: 0;
}
.footer-v1 .enterprise-code {
  text-align: center;
}
.footer-v1 .enterprise-code img {
  margin-top: 15px;
}
.footer-v1 .enterprise-code p {
  filter: alpha(opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
  font-size: 12px;
  color: #fff;
  margin-top: 8px;
}
.footer-v1 .copyright {
  border-top: 1px solid #272a32;
  background: #191c24;
  padding-top: 0;
}
.footer-v1 .copyright .footer-socials {
  margin-top: 20px;
  text-align: right;
}
.footer-v1 .copyright .footer-socials li {
  padding-right: 0;
  padding-left: 2px;
}
.footer-v1 .copyright .footer-socials li a {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  text-align: center;
  width: 26px;
  background: #30333a;
}
.footer-v1 .copyright .footer-socials li a img {
  margin-top: -2px;
}
.footer-v1 .copyright .footer-socials li a:hover,
.footer-v1 .copyright .footer-socials li a:focus {
  background: #fb6314;
}
.footer-v1 .copyright p {
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
  font-size: 12px;
  color: #fff;
  margin-top: 14px;
}
.modal-content {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.modal-content .modal-header {
  background: #f5f5f5;
  padding: 15px 18px 7px 18px;
  height: 60px;
  line-height: 60px;
}
.modal-content .close {
  font-weight: normal;
  font-size: 36px;
  margin-top: -6px;
}
.modal-content .modal-title {
  font-size: 20px;
  color: #404040;
  border: 0;
  text-align: left;
}
.modal-content .modal-body {
  padding-bottom: 45px;
}
.modal-dialog {
  margin-top: 300px;
  width: 700px;
}
.modal-dialog .form-horizontal.sky-form {
  margin-top: 25px;
  border: 0;
}
.modal-dialog .form-horizontal.sky-form .form-group {
  margin-bottom: 14px;
}
.modal-dialog .form-horizontal.sky-form .form-group .control-label {
  float: left;
  font-weight: normal;
  font-size: 14px;
  color: #404040;
  width: 244px;
  height: 32px;
  padding-top: 0;
  line-height: 32px;
}
.modal-dialog .form-horizontal.sky-form .form-group .control-input {
  padding-left: 260px;
  margin-right: 160px;
}
.modal-dialog .form-horizontal.sky-form .form-group .control-input input {
  border-color: #e0e0e0;
}
.modal-dialog .form-horizontal.sky-form .form-group .control-input .radio {
  display: inline-block;
  line-height: 16px;
  margin-right: 26px;
}
.modal-dialog .form-horizontal.sky-form .form-group .control-input .add-img {
  display: inline-block;
  margin-top: 2px;
}
.modal-dialog .modal-body {
  padding-bottom: 25px;
}
.modal-dialog .modal-footer {
  text-align: center;
  background: #f5f5f5;
  border: 0;
}
.modal-dialog .modal-footer button {
  font-size: 20px;
  padding: 7px 40px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  line-height: 16px;
  height: 40px;
}
.modal-dialog .modal-footer .btn-warning {
  background: #ff5b22;
  border: 1px solid #ff5b22;
  margin-right: 20px;
}
.modal-dialog .modal-footer .btn-warning:hover,
.modal-dialog .modal-footer .btn-warning:focus {
  background: #fb5015;
  border-color: #fb5015;
}
.modal-dialog .modal-footer .btn-default {
  background: #afafaf;
  border: 1px solid #afafaf;
}
.modal-dialog .modal-footer .btn-default:hover,
.modal-dialog .modal-footer .btn-default:focus {
  background: #afafaf;
  border-color: #afafaf;
}
.page-side {
  width: 210px;
  float: left;
  background: #fff;
}
.page-side .list-toggle::after {
  content: normal;
}
.page-side .list-group-item {
  border: 0;
}
.page-side .sidebar-nav-v1 {
  font-size: 16px;
  background: #fff none repeat scroll 0 0;
  padding-top: 16px;
}
.page-side .sidebar-nav-v1 img {
  display: inline-block;
  margin-right: 16px;
  margin-left: 20px;
}
.page-side .sidebar-nav-v1 li a {
  color: #333;
  padding-left: 0;
}
.page-side .sidebar-nav-v1 li.active,
.page-side .sidebar-nav-v1 li.active:hover {
  background: #fff;
}
.page-side .sidebar-nav-v1 li.active ul a,
.page-side .sidebar-nav-v1 li.active:hover ul a {
  background: #fff;
}
.page-side .sidebar-nav-v1 li.active .active a {
  border-left-color: #ff5b22;
  background: #ebeff0;
}
.page-side .sidebar-nav-v1 li.active .active:hover,
.page-side .sidebar-nav-v1 li.active .active:focus {
  border-left: 0px solid #ff5b22;
  background: #ebeff0;
}
.page-side .sidebar-nav-v1 li ul,
.page-side .sidebar-nav-v1 li.active ul {
  background: #fff;
}
.page-side .sidebar-nav-v1 li ul a,
.page-side .sidebar-nav-v1 li.active ul a {
  border: 0;
  padding: 9px 0 9px 15px;
  font-size: 16px;
  border-left: 10px solid #fff;
  color: #666;
}
.page-side .sidebar-nav-v1 li ul a:hover,
.page-side .sidebar-nav-v1 li.active ul a:hover,
.page-side .sidebar-nav-v1 li ul a:focus,
.page-side .sidebar-nav-v1 li.active ul a:focus {
  border-left: 10px solid #ff5b22;
  background: #ebeff0;
  font-size: 16px;
}
.page-side .sidebar-nav-v1 .list-group-item {
  padding: 0 0 2px 0;
  font-size: 16px;
}
.page-breadcrumb {
  margin-top: 94px;
  font-size: 14px;
  color: #666;
}
.page-breadcrumb .breadcrumb {
  padding: 0;
  top: 14px;
  margin-bottom: 24px;
}
.page-breadcrumb .breadcrumb li {
  color: #666;
}
.page-breadcrumb .breadcrumb li a:hover,
.page-breadcrumb .breadcrumb li a:focus {
  color: #ff5b22;
}
.breadcrumb > li + li::before {
  content: "> ";
  padding: 0;
  color: #666;
}
.sky-form .textarea textarea {
  padding: 9px 10px ;
}
.toolbar-group .toolbar-left {
  float: left;
}
.toolbar-group .toolbar-left .form-main {
  float: left;
  display: inline-table;
  margin-bottom: 9px;
  margin-left: 12px;
}
.toolbar-group .toolbar-left .form-main input::-webkit-input-placeholder {
  color: #d9d9d9;
}
.toolbar-group .toolbar-left .form-main input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d9d9d9;
}
.toolbar-group .toolbar-left .form-main input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d9d9d9;
}
.toolbar-group .toolbar-left .form-main input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d9d9d9;
}
.toolbar-group .toolbar-left .form-main .form-control {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}
.toolbar-group .toolbar-left .form-main .form-date .form-control {
  border-radius: 2px 0 0 2px;
  -webkit-border-radius: 2px 0 0 2px;
  -moz-border-radius: 2px 0 0 2px;
}
.toolbar-group .toolbar-left .form-main .input-group-addon {
  background: #e5e5e5;
  border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
}
.toolbar-group .toolbar-left .sky-form {
  float: left;
  margin-right: 14px;
}
.toolbar-group .toolbar-right {
  float: left;
}
.toolbar-group .toolbar-right label {
  margin-bottom: 0;
}
.toolbar-group .toolbar-right select {
  font-size: 14px;
  height: 30px;
  margin-top: 1px;
  width: 334px;
  color: #333;
  padding: 6px 10px;
}
.toolbar-group .toolbar-right select option {
  font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 10px;
  color: #333;
}
.toolbar-group .toolbar-right select option:hover {
  background: #EDEDED !important;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari 和 Chrome */
  appearance: none;
  color: #333;
}
.toolbar-group .toolbar-right .sky-form {
  float: left;
}
.toolbar-group .sky-form {
  border: 0;
}
.toolbar-group .sky-form .select i {
  top: 18px;
}
.toolbar-group .btn {
  padding: 6px 20px 6px 42px;
  font-size: 14px;
}
.toolbar-group .btn img {
  display: inline-block;
  margin-right: 10px;
}
.toolbar-group .btn-search {
  background: url("../img/btn-search.png") no-repeat 16px 10px;
  color: #ff5b22;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
  margin-left: 12px;
}
.toolbar-group .btn-search:hover,
.toolbar-group .btn-search:focus {
  background-image: url("../img/btn-search.png");
  background-position: 16px 10px ;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #ff5b22;
}
.toolbar-group .btn-recharge {
  /*background: url("../img/btn-recharge.png") no-repeat 19px 6px;*/
}
.toolbar-group .btn-recharge:hover,
.toolbar-group .btn-recharge:focus {
  /*background-image: url("../img/btn-recharge-hover.png");*/
  background-position: 19px 6px ;
  background-repeat: no-repeat;
  background-color: #fb5700 ;
}
.toolbar-group .btn-export {
  /*background: url("../img/btn-export.png") no-repeat 17px 6px;*/
}
.toolbar-group .btn-export:hover,
.toolbar-group .btn-export:focus {
  /*background-image: url("../img/btn-export-hover.png");*/
  background-position: 19px 6px ;
  background-repeat: no-repeat;
  background-color: #fb5700 ;
}
.toolbar-group .btn.last {
  margin-right: 0;
}
.table-content.sky-form {
  border: 0;
  margin-top: 10px;
}
.table-content.sky-form .table {
  margin-bottom: 20px;
}
.table-content.sky-form .page-number {
  margin-bottom: 20px;
}
.table {
  border-collapse: separate;
}
.table tbody tr td,
.table tfoot tr td {
  background: #f8f8f8;
  border-bottom: 1px dotted #e5e5e5;
  border-top: 0;
  text-align: center;
  color: #666;
  padding: 12px 8px;
  font-size: 14px;
}
.table tbody tr td .checkbox,
.table tfoot tr td .checkbox {
  top: -4px;
}
.table tbody .active td,
.table tfoot .active td {
  background: #f8f3f0 !important;
}
.table thead tr th,
.table thead tr td {
  background: #f4f5f9;
  border-bottom: 1px solid #d9d9d9 !important;
  border-top: 1px solid #d9d9d9 !important;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 100;
  padding: 10px 8px;
  position: relative;
}
.table thead tr th .desc,
.table thead tr td .desc {
  display: block;
  height: 0;
  width: 0;
  font-size: 0;
  line-height: 0;
  border-color: transparent transparent #c2c3c5 transparent;
  border-style: dashed dashed solid dashed;
  border-width: 6px;
  position: absolute;
  right: 0;
  top: 9px;
}
.table thead tr th .desc:hover,
.table thead tr td .desc:hover,
.table thead tr th .desc:focus,
.table thead tr td .desc:focus {
  cursor: pointer;
  border-color: transparent transparent #919191 transparent;
}
.table thead tr th .asc,
.table thead tr td .asc {
  display: block;
  height: 0;
  width: 0;
  font-size: 0;
  line-height: 0;
  border-color: #c2c3c5 transparent  transparent transparent ;
  border-style: solid dashed dashed dashed;
  border-width: 6px;
  position: absolute;
  right: 0;
  bottom: 8px;
}
.table thead tr th .asc:hover,
.table thead tr td .asc:hover,
.table thead tr th .asc:focus,
.table thead tr td .asc:focus {
  cursor: pointer;
  border-color: #919191 transparent  transparent transparent ;
}
.table thead tr th .active.desc,
.table thead tr td .active.desc {
  border-color: transparent transparent #919191 transparent;
}
.table thead tr th .active.desc:hover,
.table thead tr td .active.desc:hover,
.table thead tr th .active.desc:focus,
.table thead tr td .active.desc:focus {
  cursor: pointer;
}
.table thead tr th .active.asc,
.table thead tr td .active.asc {
  border-color: #919191 transparent  transparent transparent ;
}
.table thead tr th .active.asc:hover,
.table thead tr td .active.asc:hover,
.table thead tr th .active.asc:focus,
.table thead tr td .active.asc:focus {
  cursor: pointer;
}
.table thead tr .checkbox {
  top: -24px;
}
.loading-table {
  text-align: center;
  background: #f8f8f8;
  font-size: 16px;
  color: #666;
  padding-top: 30px;
  padding-bottom: 36px;
}
.loading-table img {
  display: inline-block;
  margin-right: 12px;
}
@-webkit-keyframes rotationimg {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotationimg {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotationimg {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.rotation {
  -webkit-transform: rotate(360deg);
  animation: rotationimg 3s linear infinite;
  -moz-animation: rotationimg 3s linear infinite;
  -webkit-animation: rotationimg 3s linear infinite;
  -o-animation: rotationimg 3s linear infinite;
}
.page-footer {
  text-align: center;
}
.page-footer .page-left {
  /*background: url("../img/page-left.png") no-repeat;*/
  width: 10px;
  height: 18px;
  margin-right: 16px;
  display: inline-block;
}
.page-footer .page-left:hover,
.page-footer .page-left:focus {
  cursor: pointer;
}
.page-footer .page-number {
  display: inline-block;
}
.page-footer .page-number .number {
  border: 0 solid #fff;
  color: #757575;
  padding: 1px 17px;
  font-size: 16px;
}
.page-footer .page-number .number:hover,
.page-footer .page-number .number:focus {
  background: #898787;
  color: #fff;
}
.page-footer .page-number .number.active {
  background: #757575;
  color: #fff;
}
.page-footer .page-number:hover,
.page-footer .page-number:focus {
  cursor: pointer;
}
.page-footer .page-right {
  /*background: url("../img/page-right.png") no-repeat;*/
  width: 10px;
  height: 18px;
  display: inline-block;
  margin-left: 16px;
}
.page-footer .page-right:hover,
.page-footer .page-right:focus {
  cursor: pointer;
}

