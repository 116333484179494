/***
Login page
***/

/* logo page */
body,html{height: 100%;}
#outer {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  display:table;
}

#outer[id] {
  display: table;
  position: static;
  *position:relative;
  _position: static;
}
#middle {
  position: absolute;
  top: 50%;
  left: 0;
  vertical-align:middle;
  display:table-cell;
  width:100%;
  *vertical-align:middle;
  *display:table-cell;
  *width:100%;

}
#middle[id] {
  display: table-cell;
  vertical-align: middle;
  position: static;
  top:50%;
  left:0;
  width:100%;
  *position:absolute;
}
#inner {
  position: relative;
  top: 0;
  margin: 0 auto;
  text-align:center;
}
#content{
  margin: 0 auto;
  text-align:left;
  background: url(../img/login-bg.jpg) no-repeat;
}
.input-icon i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
}
.input-icon input {
  padding-left: 50px !important;
}
.input-icon input::-webkit-input-placeholder { /* WebKit browsers */
  color: #ccc;
  font-size: 16px;
}
.input-icon input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #ccc;
  font-size: 16px;
}
.input-icon input:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #ccc;
  font-size: 16px;
}
/* .login {
  background-color: #666 !important;
} */
.login .content {
  background: url(../img/login.png) no-repeat;
  height: 635px;
  width:1165px;
  margin: 0 auto; 
  margin-bottom: 0px;
  /*padding: 30px;  */
  /*padding-top: 20px;  */
  /*padding-bottom: 15px;  */
}

.login .content h3 {
  color: #eee;
}
.login .content h4  {
  color: #eee;
}

.login .content p,
.login .content label {
  color: #fff;
}

.login .content .login-form,
.login .content .forget-form {
    padding: 0px;
    margin: 0px;
}

.login .content .form-control {
  background-color: #fff;
}

.login .content .forget-form {
  display: none;
}

.login .content .register-form {
  display: none;
}

.login .content .form-title {
  font-weight: 300;
  margin-bottom: 25px;
}

.login .content .form-actions {
  background-color: transparent;
  clear: both;
  border: 0px;
  padding: 0px 30px 55px 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.login .content .form-actions .checkbox {
  margin-left: 0;
  padding-left: 0;
}

.login .content .forget-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
}  

.login .content .register-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
}

.login .content .form-actions .checkbox {
  margin-top: 8px;
  display: inline-block;
  color: #888;
  padding-left: 27px;
}

.login .content .form-actions .btn {
  margin-top: 1px;
}

.login .content .forget-password {
  margin-top: 25px;
}

.login .content .create-account {
  border-top: 1px dotted #eee;
  padding-top: 10px;
  margin-top: 15px;
}

.login .content .create-account a {
  display: inline-block;
  margin-top: 5px;
}

/* select2 dropdowns */
.login .content .select2-container i {
  display: inline-block;
  position: relative;
  color: #ccc;
  z-index: 1;
  top:1px;
  margin: 4px 4px 0px 3px; 
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center; 
}

.login .content .has-error .select2-container i {
  color: #b94a48;
}

.login .content .select2-container a span {
  font-size: 13px;
}

.login .content .select2-container a span img {
  margin-left: 4px;
}

/* footer copyright */
.login .copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: #eee;
  font-size: 13px;
  height: 62px;
}

@media (max-width: 480px) {
  /***
  Login page
  ***/
  .login .logo {
    margin-top:10px;
  }

  .login .content {
    padding: 30px; 
    width: 222px;
  }

  .login .content h3 {
    font-size: 22px;
  }

  .login .checkbox {
    font-size: 13px;
  }
}
/* myselt footer copyright */
.login .copyright {
  width:100%;
  position:absolute;
  bottom:-20px;
  color: #888;
}
.login .content .login-form{
  padding:150px 89px 0 732px;
}
.login .form-control{
  /* height: 62px; */
  border-color: #dcdcdc;
  -webkit-border-radius:2px !important;
  -moz-border-radius:2px !important;
  border-radius:2px !important;
}
.input-icon{
  position: relative;
}
.login-user{
  position: absolute;
  left: 11px;
  top:19px;
  width: 22px;
  height: 22px;
  display: inline-block;
}
.login-password{
  position: absolute;
  left: 10px;
  top:19px;
  width: 22px;
  height: 18px;
  display: inline-block;
}
.login-close{
  position: absolute;
  right: 17px;
  top:19px;
  width: 22px;
  height: 10px;
  display: inline-block;
}
.form-user{
  margin-bottom: 29px;
}
.form-password{
  margin-bottom: 7px;
}
.login-button .btn{
    width: 100%;
    background: #f76515;
  color: #fff;
  font-size: 16px;
  box-shadow: 3px 6px 17px #fcbe9d;
  -moz-box-shadow: 3px 6px 17px #fcbe9d;
}
